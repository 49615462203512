import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, ref } from "vue";
import { publicPartnerApi } from "@/api/publicPartner";
export default defineComponent({
    setup: function () {
        var _this = this;
        var publicPartnerList = ref("");
        var getpublicPartnerList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = publicPartnerList;
                        return [4 /*yield*/, publicPartnerApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            getpublicPartnerList();
        });
        return {
            publicPartnerList: publicPartnerList,
        };
    },
});
