import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_Nav = _resolveComponent("Nav");
    var _component_HomeJumbotron = _resolveComponent("HomeJumbotron");
    var _component_HomeProduct = _resolveComponent("HomeProduct");
    var _component_HomeAboutUs = _resolveComponent("HomeAboutUs");
    var _component_HomeNews = _resolveComponent("HomeNews");
    var _component_HomePartner = _resolveComponent("HomePartner");
    var _component_BasisGroup = _resolveComponent("BasisGroup");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_Nav),
        _createVNode(_component_HomeJumbotron),
        _createVNode(_component_HomeProduct),
        _createVNode(_component_HomeAboutUs),
        _createVNode(_component_HomeNews),
        _createVNode(_component_HomePartner),
        _createVNode(_component_BasisGroup)
    ], 64));
}
