import "./index.scss";
import { defineComponent } from "vue";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
import HomePartner from "@/views/home/homePartner/index.vue";
import HomeProduct from "@/views/home/homeProduct/index.vue";
import HomeNews from "@/views/home/homeNews/index.vue";
import HomeAboutUs from "@/views/home/homeAboutUs/index.vue";
import BasisGroup from "@/views/basisGroup/index.vue";
import Nav from "@/components/nav/nav.vue";
export default defineComponent({
    components: {
        HomeJumbotron: HomeJumbotron,
        HomePartner: HomePartner,
        HomeProduct: HomeProduct,
        HomeNews: HomeNews,
        HomeAboutUs: HomeAboutUs,
        BasisGroup: BasisGroup,
        Nav: Nav,
    },
    setup: function () {
        document.documentElement.scrollTop = 0;
    },
});
