import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, ref } from "vue";
import { productApi } from "@/api/product";
import router from "@/router/index";
export default defineComponent({
    setup: function () {
        var _this = this;
        var productList = ref("");
        var getProductList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productList;
                        return [4 /*yield*/, productApi({
                                currentPage: 1,
                                size: 15,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var JumpProductDetails = function (item) {
            var productInfo = JSON.stringify(item);
            router.push({
                name: "productDetails",
                query: { productInfo: encodeURIComponent(productInfo) },
            });
        };
        onMounted(function () {
            getProductList();
        });
        return {
            productList: productList,
            JumpProductDetails: JumpProductDetails,
        };
    },
});
