import { __assign, __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { publicNewsListApi } from "@/api/news";
import router from "@/router/index";
export default defineComponent({
    setup: function () {
        var _this = this;
        var newsList = ref([]);
        var newsFirstData = reactive({
            data: {
                newsImg: "",
                newsTitle: "",
                newsTime: "",
                newsContent: "",
            },
        });
        var newsTypeList = ref("");
        var JumpNewsDetail = function (item) {
            var newsInfo = JSON.stringify(item);
            console.log("newsInfo:" + newsInfo);
            router.push({
                name: "newsDetail",
                query: { newsInfo: encodeURIComponent(newsInfo) },
            });
        };
        var getnewsList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _b, _c, _d, _e, _f, _g, _h, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        _a = newsList;
                        return [4 /*yield*/, publicNewsListApi({
                                currentPage: 1,
                                size: 4,
                            })];
                    case 1:
                        _a.value = _k.sent();
                        newsFirstData.data.newsImg = (_c = (_b = newsList.value) === null || _b === void 0 ? void 0 : _b.records[0]) === null || _c === void 0 ? void 0 : _c.newsImg;
                        newsFirstData.data.newsTitle = (_e = (_d = newsList.value) === null || _d === void 0 ? void 0 : _d.records[0]) === null || _e === void 0 ? void 0 : _e.newsTitle;
                        newsFirstData.data.newsTime = (_g = (_f = newsList.value) === null || _f === void 0 ? void 0 : _f.records[0]) === null || _g === void 0 ? void 0 : _g.newsTime;
                        newsFirstData.data.newsContent = (_j = (_h = newsList.value) === null || _h === void 0 ? void 0 : _h.records[0]) === null || _j === void 0 ? void 0 : _j.newsContent;
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            getnewsList();
        });
        return __assign(__assign({ newsList: newsList,
            newsTypeList: newsTypeList }, newsFirstData), { JumpNewsDetail: JumpNewsDetail });
    },
});
